"use strict";

export default class Filters {

    // update the query string with the selected filters
    static updateQueryString($form) {

        // update rooms filter in query string
        const $roomsInput = $('input[name="pieces_number[]"]:checked');
        if($roomsInput.length !== 0){
            var selectedRooms = [];
            $roomsInput.each(function() {
                selectedRooms.push($(this).val());
            });
            selectedRooms = selectedRooms.join(',');
        }

        // update energy class filter in query string
        const $energyClassInput = $('input[name="energy_class[]"]:checked');
        if($energyClassInput.length !== 0){
            var selectedEnergyClass = [];
            $energyClassInput.each(function() {
                selectedEnergyClass.push($(this).val());
            });
            selectedEnergyClass = selectedEnergyClass.join(',');
        }
    
        // update bedrooms filter in query string
        const $bedroomsInput = $('input[name="chambers_number[]"]:checked');
        if($bedroomsInput.length !== 0){
            var selectedBedrooms = [];
            $bedroomsInput.each(function() {
                selectedBedrooms.push($(this).val());
            });
            selectedBedrooms = selectedBedrooms.join(',');
        }

        // update bedrooms filter in query string
        const $realEstateTypesInput = $('input[name="realestate_type[]"]:checked');
        if($realEstateTypesInput.length !== 0){
            var selectedTypes = [];
            $realEstateTypesInput.each(function() {
                selectedTypes.push($(this).val());
            });
            selectedTypes = selectedTypes.join(',');
        }
    
        // Serialize the form
        let queryString = $form.serialize();
    
        // Split the query string into key-value pairs
        let pairs = queryString.split('&');
    
        // Filter out pairs with empty values
        let filteredPairs = pairs.filter(function (pair) {
            let parts = pair.split('=');
            // Check if the value part is not empty and the key is not pieces_number[] or chambers_number[]
            return parts[1] !== '' && 
            decodeURIComponent(parts[0]) !== 'pieces_number[]' && 
            decodeURIComponent(parts[0]) !== 'chambers_number[]' &&
            decodeURIComponent(parts[0]) !== 'energy_class[]' &&
            decodeURIComponent(parts[0]) !== 'realestate_type[]';
        });
    
        // Join the filtered pairs back into a query string
        queryString = filteredPairs.join('&');
    
        // Add the selected rooms and bedrooms to the query string
        if(selectedRooms !== undefined && selectedRooms !== ''){
            queryString += `&rooms=${selectedRooms}`;
        }
        if(selectedBedrooms !== undefined && selectedBedrooms !== ''){
            queryString += `&bedrooms=${selectedBedrooms}`;
        }

        // Add the selected energy class to the query string
        if(selectedEnergyClass !== undefined && selectedEnergyClass !== ''){
            queryString += `&energy_class=${selectedEnergyClass}`;
        }

        // Add the selected real estate types to the query string
        if(selectedTypes !== undefined && selectedTypes !== ''){
            queryString += `&realestate_type=${selectedTypes}`;
        }

        return queryString;
    }

    static mirrorValueToHtml(input){ 
        // Set the value of the input to the value of its 'data-value' attribute
        var dataValue = $(input).val(); 
        $(input).attr('data-value', dataValue);            
    }

    static setStateRealEstateTypeFilter(type){

        const url = $('#types-dropdown').data('set-state-route');
        const data = {'realEstateType' : type};
        $.ajax({
            headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
            url,
            data,
            type: 'POST',
            success: function(response){
                // console.log(response.message);
            },
            error: function(error){
                console.log(error);
            }
        });
    }

    static resetStateRealEstateTypeFilter(){

        const url = $('#types-dropdown').data('reset-state-route');
        $.ajax({
            headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
            url,
            type: 'GET',
            success: function(response){
                // console.log(response.message);
            },
            error: function(error){
                console.log(error);
            }
        });
    }
}